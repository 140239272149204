export function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04494 20.6484C4.04494 21.2006 4.49768 21.6484 5.05617 21.6484H19.2135C19.772 21.6484 20.2247 21.2006 20.2247 20.6484V11.6484H23.2584L12.8154 2.26036C12.4296 1.91321 11.8401 1.91321 11.4543 2.26036L1.01123 11.6484H4.04494V20.6484ZM18.2022 9.80536V19.6484H6.06741V9.80536L12.1348 4.35136L18.2022 9.80536Z"
        fill="#001018"
      />
    </svg>
  )
}
